<template>
  <div id="bottom-auto">
     <div class="book-item" v-for="(item,index) in datalist" :key="index">
      <div class="mid-page-third">
        <div class="mid-page-third-box">
          <img class="mid-page-third-image" :src="item.image" alt="">
          <p class="mid-page-third-type" v-if="item.image!==''">{{ item.type }}</p>
        </div>
      </div>
      <div class="mid-page-first">
        <p>书名：{{ item.one }}</p>
        <p>作者：{{ item.five }}</p>
        <p>ISBN：{{ item.three }}</p>
      </div>
      <div class="mid-page-second">
        <p>&nbsp;</p>
        <p>出版社：{{ item.four }}</p>
        <p>版次：{{ item.six }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['books'],
  data () {
    return {
      firstTimer: null,
      datalist: []
    }
  },
  methods: {
    changeScroll () {
      clearInterval(this.firstTimer)
      const bottomDom = document.getElementById('bottom-auto')
      let i = 1
      this.firstTimer = setInterval(() => {
        bottomDom.scrollTop = Math.ceil(++i)
        if ((bottomDom.clientHeight + bottomDom.scrollTop) >= bottomDom.scrollHeight) {
          if (this.datalist.length < 4) {

          } else {
            const arr = this.datalist
            const resultArr = arr.concat(this.datalist)
            this.datalist = resultArr
          }
        }
      }, 200)
    }
  },
  watch: {
    books: {
      handler (newValue) {
        console.log(newValue)
        newValue.forEach(item => {
          this.datalist.push({
            one: item.name,
            three: item.booksNumber,
            four: item.publisher,
            five: item.author,
            six: item.edition,
            type: item.typeName,
            image: item.cover
          })
        })
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.changeScroll()
    }, 100)
  },
  destroyed () {
    clearInterval(this.firstTimer)
  }
}
</script>
<style scoped>
  #bottom-auto{
    width: 100%;
    margin-bottom: 2rem;
  }
  #bottom-auto::-webkit-scrollbar{
    display: none;
  }
  .book-item{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    color: #00D7F6;
    font-size: 8px;
    margin: 10px 0px;
  }
  .mid-page-first{
    width: 40%;
    margin-right: 30px;
  }
  .mid-page-first p:nth-child(1){
    margin-bottom: 1vh;
  }
  .mid-page-second{
    width: 40%;
  }
  .mid-page-second p:nth-child(2){
    margin-top: 1vh;
  }
  .mid-page-third{
    margin-right: 20px;
    width: 20%;
  }
  .mid-page-third-box{
    position: relative;
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
  .mid-page-third-image{
    width: 100%
  }
  .mid-page-third-type{
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: #b2b2b2;
    color: #fff;
    border-radius: 3px;
    padding: 1px 2px;
    font-size: 8px;
    transform: scale(0.8);
  }
</style>
