<template>
  <div class="first-home">
    <Header :titleName="title" />
    <div id="thirdChart"></div>
  </div>
</template>
<script>
import Highcharts from 'highcharts'
import Header from '../../../components/header-title.vue'
export default {
  components: {
    Header
  },
  props: ['unitamount'],
  data () {
    return {
      datalist: [],
      title: '',
      year: []
    }
  },
  watch: {
    unitamount: {
      handler (newValue) {
        this.year = []
        this.title = newValue.title
        const firstArr = []
        const secondArr = []
        newValue.data.forEach((item) => {
          firstArr.push(item.y1)
          secondArr.push(item.y)
          this.year.push(`${item.name}年`)
        })
        this.datalist.push(
          {
            name: '送审单位总量',
            data: firstArr,
            stack: 'male',
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, '#4D68C4'],
                [1, '#00FFCC']
              ]
            }
          },
          {
            name: '走审单位总量',
            data: secondArr,
            stack: 'male',
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, '#7626FF'],
                [1, '#FF7D7D']
              ]
            }
          }
        )
        this.beginChart()
      }
    }
  },
  mounted () {
    this.beginChart()
  },
  methods: {
    beginChart () {
      Highcharts.chart('thirdChart', {
        chart: {
          type: 'cylinder',
          backgroundColor: '#142c89',
          margin: [20, 50, 40, 50],
          padding: [0, 0, 0, 0],
          options3d: {
            enabled: true,
            alpha: 20,
            beta: 0,
            depth: 0
          }
        },
        title: {
          text: '' // 图表的标题文字
        },
        subtitle: {
          text: '' // 副标题文字
        },
        yAxis: {
          title: {
            align: 'high',
            offset: 0,
            text: '(单位：家)',
            rotation: 0,
            y: -10,
            x: 30,
            style: { color: '#7FC8FF' }
          },
          labels: {
            style: { color: '#7FC8FF' }
          },
          gridLineColor: '#00D7F6',
          tickAmount: 4
        },
        xAxis: {
          type: 'category',
          gridLineColor: '#00D7F6',
          lineColor: '#00D7F6',
          title: {
            align: 'high',
            offset: 0,
            text: '(时间)',
            rotation: 0,
            y: -5,
            x: 40,
            style: { color: '#7FC8FF' }
          },
          labels: {
            style: { color: '#7FC8FF' }
          },
          categories: this.year,
          crosshair: true
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: true,
          align: 'center', // 程度标的目标地位
          verticalAlign: 'bottom', // 垂直标的目标地位
          x: 0, // 间隔x轴的间隔
          y: 20, // 间隔Y轴的间隔
          itemStyle: { color: '#7FC8FF' }
        },
        plotOptions: {
          column: {
            depth: 25
          },
          series: {
            depth: 25,
            dataLabels: {
              color: '#fff',
              enabled: true,
              defer: false,
              format: '{point.y}'
            }
          }
        },
        series: this.datalist
      })
    }
  }
}
</script>
<style scoped>
.first-home {
  /* background: url("../../../assets/left.jpg"); */
  background: #142c89;
  background-size: 100% 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  /* border: 2px solid rgb(60, 129, 195); */
}
#thirdChart {
  width: 100%;
  flex: 1;
}
</style>
