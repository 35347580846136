<template>
  <div class="home" @click="goback">
    <img class="second-header-image" src="../../../assets/logo.png" alt="">
    <div class="home-title">
      <p class="second-header-title">广州市图批创新中心运营管理有限公司</p>
      <p class="second-header-titleSecond">审读系统</p>
      <p class="second-header-titleThird">{{ year }}年问题图书数据汇总</p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  props: ['year'],
  data () {
    return {

    }
  },
  methods: {
    goback () {
      
      // 清除查询年份session
      sessionStorage.removeItem('searchYear')

      const _prop = Vue.prototype
      _prop.changePage()
    }
  }
}
</script>
<style scoped>
  .home{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 5%;
    cursor: pointer;
  }
  .second-header-image{
    width: 10vw;
  }
  .home-title{
    color: #fff;
  }
  .second-header-title{
    font-weight: bold;
    font-size: 1.5vw;
  }
  .second-header-titleSecond{
    font-weight: bold;
    font-size: 1.5vw;
  }
  .second-header-titleThird{
    font-size: 1vw;
    color: #00D7F6;
    font-weight: bold;
    margin-top: 1vh;
  }
</style>
