<template>
  <div class="left-home">
    <firstChar class="child" :top='top'></firstChar>
    <secondChar class="child" :classify='classify'></secondChar>
    <thirdChar class="child" :problem='problem'></thirdChar>
  </div>
</template>
<script>
import firstChar from './firstChar.vue'
import secondChar from './secodChar.vue'
import thirdChar from './thirdChar.vue'
export default {
  components: {
    firstChar,
    secondChar,
    thirdChar
  },
  props: ['classify', 'top', 'problem'],
  data () {
    return {

    }
  }
}
</script>
<style scoped>
  .left-home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .child{
    flex: 1;
  }
</style>
