<template>
  <div class="first-home">
    <Header :titleName="'年度审读问题书刊'" />
    <div id="new-list">
      <div class="new-list-item" v-for="(item,index) in datalist" :key="index">
        <div style="width:70px;position:relative;margin-right:20px">
          <img class="new-list-item-img" :src="item.cover" alt="">
          <p class="new-list-item-type">{{ item.typeName }}</p>
        </div>
        <div class="new-list-item-first">
          <p style="">书名：{{ item.name }}</p>
          <p>ISBN：{{ item.booksNumber }}</p>
          <p>出版社:{{ item.publisher }}</p>
        </div>
        <div class="new-list-item-second">
          <p>检查情况：{{ item.checkBooks }}</p>
          <p>处理情况：{{ item.handleBooks }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from '../../../../components/header-title.vue'
export default {
  components: {
    Header
  },
  props: ['books'],
  data () {
    return {
      secondTimer: null,
      datalist: []
    }
  },
  methods: {
    changeScroll (newValue) {
      clearInterval(this.secondTimer)
      const bottomDom = document.getElementById('new-list')
      let i = 1
      this.secondTimer = setInterval(() => {
        bottomDom.scrollTop = Math.ceil(++i)
        if ((bottomDom.clientHeight + bottomDom.scrollTop) >= bottomDom.scrollHeight) {
          // let arr = newValue;
          this.datalist = this.datalist.concat(newValue)
        }
      }, 100)
    }
  },
  watch: {
    books: {
      handler (newValue) {
        console.log(newValue)
        if (newValue.length>2) {
          this.changeScroll(newValue)
        }
        else
          this.datalist = newValue        
      },
      deep: true
    }
  },
  destroyed () {
    clearInterval(this.secondTimer)
  }
}
</script>
<style scoped>
  .first-home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(60, 129, 195);
    background: #142c89;
    background-size: 100% 100%;
    padding: 1rem;
  }
  #new-list{
    color: #7FC8FF;
    /* overflow-y: auto; */
    flex: 1;
    margin: 20px 0;
  }
  #new-list::-webkit-scrollbar{
    display: none;
  }
  .new-list-item{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    color: #00D7F6;
    margin-top: 10px;
  }
  .new-list-item-img{
    width: 100%;
  }
  .new-list-item-type{
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: #b2b2b2;
    color: #fff;
    border-radius: 5px;
    padding: 1px 2px;
    font-size: 8px;
    transform: scale(0.8);
  }
  .new-list-item-first{
    flex: 1;
    margin-right: 20px;
  }
  .new-list-item-first p{
    font-size: 8px;
  }
  .new-list-item-second{
    /* flex: 1; */
  }
  .new-list-item-second p{
    font-size: 8px;
  }
</style>
