<template>
  <div class="home">
    <img class="home-img" src="../../../assets/logo.png" alt="">
    <div>
      <p class="home-title">广州市图批创新中心运营管理有限公司</p>
      <p class="home-title">审读系统</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  }
}
</script>
<style scoped>
.home{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }
.home-img{
  width: 4vw;
  height: 5vh;
}
.home-title{
  color: #fff;
  font-size: 1.5vw;
  flex: 1;
}
</style>
