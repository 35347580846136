<template>
  <div class="title">
    <img class="title-image-icon" src="../assets/leftIcon.png" alt="" />
    <div class="title-mid">
      <p @click="handleNext">[{{titleName}}]</p>
      <img class="underline" src="../assets/text_underline.png" alt="" />
    </div>
    <img class="title-image-icon" src="../assets/rightIcon.png" alt="" />
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  props: ['titleName', 'type'],
  data () {
    return {}
  },
  methods: {
    handleNext () {
      if (this.type == 'next') {
        const _prop = Vue.prototype
        _prop.changePage()
      }
    }
  }
}
</script>
<style scoped>
.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  background: url("../assets/title_bg.png");
  background-size: 100% 100%;
  padding: .5vh 0;
}
.title-image-icon{
  width: 3vw;
}
.title-mid{
  text-align: center;
  font-size: .7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 .5vw;
}
.underline{
  width: 10vw;
  margin-top: .5vh;
}
</style>
