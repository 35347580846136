<template>
  <div>
    <div class="content-home">
      <firstChart class="child" :problem="chartData.problem"></firstChart>
      <secondChart class="child" :classify="chartData.classify"></secondChart>
      <thirdChart class="child" :books="chartData.books"></thirdChart>
    </div>
    <div>
      <p class="icp"><a class="icp" href="https://beian.miit.gov.cn">粤ICP备2022042242号-1</a></p>
    </div>
  </div>
</template>
<script>
import firstChart from "./components/firstChart.vue";
import secondChart from "./components/secondChart.vue";
import thirdChart from "./components/thirdChart.vue";
import Axios from "axios";
export default {

  components: {
    firstChart,
    secondChart,
    thirdChart,
  },

  data() {
    return {
      chartData: {
        books: [],
        classify: [],
        problem: [],
      },
      year: new Date().getFullYear(),
    };
  },

  methods: {
    async getSecondChartData(year) {
      this.$emit("getYears", year);
      const { data } = await Axios({
        url: "/admin/statistic/screenTwo",
        method: "post",
        data: {
          year: year,
        },
      });
      if (data.code == 200) {
        data.data.year = this.year;
        this.handleSetLocalstorage(JSON.stringify(data.data));
        const { books, classify, problem } = { ...data.data };
        this.chartData.books = books;
        this.chartData.classify = classify;
        this.chartData.problem = problem;
      }
    },

    handleSetLocalstorage(data) {
      window.sessionStorage.setItem("secondPageData", data);
    },

    handleGetLocalstorage() {
      const data = JSON.parse(window.sessionStorage.getItem("secondPageData"));
      return data;
    },
  },

  mounted() {
    const sessionData = this.handleGetLocalstorage();
    const searchYear = sessionStorage.getItem('searchYear');

    // 点击年份跳转第二页查询
    if(searchYear) {
        this.getSecondChartData(searchYear);
        return
    }

    if (sessionData) {
      this.chartData.books = sessionData.books;
      this.chartData.classify = sessionData.classify;
      this.chartData.problem = sessionData.problem;
      this.year = sessionData.year;
      this.$emit("getYears", this.year);
    } else {
      var query = window.location.search.substring(1);
      if (query) {
        const year = query.split("=")[1];
        this.year = year;
        this.getSecondChartData(year);
      } else {
        this.getSecondChartData(this.year);
      }
    }
  },
}

</script>
<style scoped>
.content-home {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.icp {
  width: 100%;
  color: #fff;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.child {
  flex: 1;
  margin: 0 2vw;
  height: 40vh;
}
</style>
