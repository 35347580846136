<template>
  <div class="left-home">
    <firstChar class="child" :reviewall='reviewall'></firstChar>
    <secondChar class="child" :reviewquantity='reviewquantity'></secondChar>
    <thirdChar class="child" :unitamount='unitamount'></thirdChar>
  </div>
</template>
<script>
import firstChar from './firstChar.vue'
import secondChar from './secondChar.vue'
import thirdChar from './thirdChar.vue'
export default {
  components: {
    firstChar,
    secondChar,
    thirdChar
  },
  props: ['reviewall', 'reviewquantity', 'unitamount'],
  data () {
    return {

    }
  },
  mounted () {
    window.resize = (e) => {
      console.log(e)
    }
  }
}
</script>
<style scoped>
  .left-home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .child{
    flex: 1;
  }
</style>
