// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/title_bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title[data-v-5975eef2]{display:flex;flex-direction:row;justify-content:center;align-items:center;color:#fff;width:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;padding:.5vh 0}.title-image-icon[data-v-5975eef2]{width:3vw}.title-mid[data-v-5975eef2]{text-align:center;font-size:.7vw;display:flex;flex-direction:column;justify-content:center;align-items:center;margin:0 .5vw}.underline[data-v-5975eef2]{width:10vw;margin-top:.5vh}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
