<template>
  <div class="first-home">
    <Header :titleName="title" />
    <div class="new-list">
      <p v-for="(item,index) in datalist" :key="index">{{ `${index+1}.${item.name}` }}</p>
    </div>
  </div>
</template>
<script>
import Header from '../../../../components/header-title.vue'
export default {
  components: {
    Header
  },
  props: ['problem'],
  data () {
    return {
      datalist: [],
      title: ''
    }
  },
  watch: {
    problem: {
      handler (newValue) {
        this.title = newValue.title
        this.datalist = newValue.data
      },
      deep: true
    }
  }
}
</script>
<style scoped>
  .first-home{
    background: #142c89;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 2px solid rgb(60, 129, 195);
  }
  .new-list{
    color: #00D7F6;
    overflow-y: auto;
    flex: 1;
  }
  .new-list::-webkit-scrollbar{
    display: none;
  }
  .new-list p{
    font-size: 0.8vw;
    margin-top: 1vh;
  }
</style>
