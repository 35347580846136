<template>
  <div class="home">
    <div class="home-des">
      <p>{{ total }}条审读数据</p>
      <p>自2007年至今</p>
    </div>
    <img class="home-img" src="../../../assets/dt2.jpg" alt="">
  </div>
</template>
<script>
export default {
  props: ['year', 'total'],
  data () {
    return {

    }
  },
  mounted () {
  }
}
</script>
<style scoped>
.home{
    position: relative;
    display: flex;
    justify-content: center;
  }
.home-des{
  position: absolute;
  top: 0px;
  left: 0px;
}
.home-des p{
  color: #00D7F6;
  font-size: 1vw;
}
.home-des p:nth-child(1){
  font-weight: bold;
  font-weight: 1rem;
}
.home-des p:nth-child(2){
  font-size: 0.5vw;
}
.home-img{
  width: 90%;
}
</style>
