<template>
  <div class="mid-home">
    <firstChar class="firstChild"></firstChar>
    <secondChar class="secondChild" :year="year" :total="total"></secondChar>
    <thirdChar class="thirdChild" :books='books'></thirdChar>
    <div><a class="icp" href="https://beian.miit.gov.cn">粤ICP备2022042242号-1</a></div>
  </div>
</template>
<script>
import firstChar from './firstChar.vue'
import secondChar from './secondChar.vue'
import thirdChar from './thirdChar.vue'
export default {
  components: {
    firstChar,
    secondChar,
    thirdChar
  },
  props: ['books', 'year', 'total'],
  data () {
    return {

    }
  },
  methods:{
     openUrl() {
        window.location.href = ''
     }
  },
  mounted () {
  }
}
</script>
<style scoped>
  .mid-home{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* margin-top: 1rem; */
  }
  .firstChild{
    margin-top: 5%;
  }
  .icp {
    color: #fff;
    margin-bottom: 1rem;
  }
  .secondChild{
    margin-top: 1rem;
  }
  .thirdChild{
    flex: 1;
    margin-top: 1rem;
  }
</style>
