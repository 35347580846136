<template>
  <div class="header-home">
    <headerSecond :year='year'></headerSecond>
    <contentSecond class="contentSecond" @getYears='getYears'></contentSecond>
  </div>
</template>
<script>
import headerSecond from './headerSecond/headerSecond.vue'
import contentSecond from './contentSecond/contentSecond.vue'
export default {
  components: {
    headerSecond,
    contentSecond
  },
  data () {
    return {
      year: ''
    }
  },
  methods: {
    getYears (year) {
      this.year = year
    }
  }
}
</script>
<style scoped>
  .header-home{
    background: url("../../assets/2D.jpg");
    background-size: 100% 100%;
    position: fixed;
    width: 100%;
    height: 100%;
  }
  .contentSecond{

    margin-top: 10%;
  }
</style>
