<template>
  <div id="page">
    <button @click="openScrollFull" class="fullScrollBtn">
      {{ isFullScroll?'退出':'全屏' }}
    </button>
    <!-- <button @click="nextPage" style="position:fixed;bottom:0;right:0;z-index:99">{{ currentIndex?'下一页':'返回上一页' }}</button> -->
    <transitionGroup mode="in-out" name="fade">
      <homePage ref="homePage" v-if="currentIndex == true" key="1"></homePage>
      <homePageSecond
        ref="homePageSecond"
        v-if="currentIndex == false"
        key="2"
      ></homePageSecond>
    </transitionGroup>
  </div>
</template>

<script>
import homePage from './homePage/homePage.vue'
import homePageSecond from './homePageSecond/homePageSecond.vue'
import Vue from 'vue'
export default {
  name: 'Home',
  components: {
    homePage,
    homePageSecond
  },
  data () {
    return {
      currentIndex: true,
      isFullScroll: false
    }
  },
  methods: {
    openScrollFull () {
      const body = document.body
      this.isFullScroll == true
        ? this.closeFullScroll()
        : this.requestFullScreen(body)
    },
    closeFullScroll () {
      document.exitFullscreen()
      this.isFullScroll = false
    },
    requestFullScreen (element) {
      var requestMethod =
        element.requestFullScreen ||
        element.webkitRequestFullScreen ||
        element.mozRequestFullScreen ||
        element.msRequestFullScreen
      if (requestMethod) {
        requestMethod.call(element)
        this.isFullScroll = true
      }
    },

    nextPage (year) {
        if(year) sessionStorage.setItem('searchYear',year)
        this.currentIndex = !this.currentIndex
    },

    // 监听浏览器刷新
    bindCloseBrowser () {
      window.onbeforeunload = function (b) {
        window.sessionStorage.clear()
        // window.sessionStorage.removeItem('secondPageData')
        // window.sessionStorage.removeItem('firstPageData')
      }
    }
  },
  mounted () {
    this.bindCloseBrowser()
    Vue.prototype.changePage = this.nextPage
  }
}
</script>

<style scoped>
.home {
  /* background: url("../assets/55.jpg");
  background-size: 100% 100%;
  height: 100%;
  position: fixed;
  width: 100%; */
}
.fullScrollBtn {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  opacity: 0.1;
  cursor: pointer;
  transition: .3s;
}
.fullScrollBtn:hover{
  opacity: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
