<template>
  <div class="first-home">
    <Header :titleName="title"/>
    <div id="secondRightChart"></div>
  </div>
</template>
<script>
import Highcharts from 'highcharts'
import Header from '../../../components/header-title.vue'
export default {
  components: {
    Header
  },
  props: ['classify'],
  data () {
    return {
      datalist: [],
      title: ''
    }
  },
  methods: {
    beginChar () {
      Highcharts.chart('secondRightChart', {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 60,
            beta: 0
          },
          backgroundColor: '#142c89'
        },
        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        credits: {
          enabled: false
        },
        legend: {
          align: 'right',
          layout: 'vertical',
          symbolRadius: 0,
          itemStyle: { color: '#7FC8FF' }
        },
        plotOptions: {
          pie: {
            // size:'200%',
            allowPointSelect: true,
            cursor: 'pointer',
            depth: 20,
            dataLabels: {
              enabled: false,
              format: '{point.name}'
            },
            showInLegend: {
              enabled: true
            },
            colors: ['#8B47FF', '#95D5F5', '#E25D9D', '#FEE17F', '#BEBEDF', '#ee7a0b','#66dc7a']
          }
        },
        series: [
          {
            name: '占比',
            data: this.datalist
          }
        ]
      })
    }
  },
  watch: {
    classify: {
      handler (newValue) {
        this.title = newValue.title
        newValue.data.forEach(item => {
          this.datalist.push({
            name: item.name,
            y: item.y
          })
        })
        this.beginChar()
      }
    }
  },
  mounted () {
    // this.beginChar();
  }
}
</script>
<style scoped>
 .first-home {
  /* background: url("../../../assets/left.jpg"); */
  background: #142c89;
  background-size: 100% 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  /* border: 2px solid rgb(60, 129, 195); */
}
#secondRightChart {
  width: 100%;
  flex: 1;
}
</style>
