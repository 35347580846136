<template>
  <div class="first-home">
    <Header :titleName="title" />
    <div id="firstChart"></div>
  </div>
</template>
<script>
import Header from '../../../components/header-title.vue'
import Highcharts from 'highcharts'
export default {
  components: {
    Header
  },
  props: ['reviewall'],
  data () {
    return {
      title: '',
      datalist: []
    }
  },
  methods: {
    beginChar () {
      Highcharts.chart('firstChart', {
        chart: {
          type: 'column',
          backgroundColor: '#142c89',
          margin: [30, 50, 30, 50],
          padding: [0, 0, 0, 0]
        },
        xAxis: {
          type: 'category',
          gridLineColor: '#00D7F6',
          lineColor: '#00D7F6',
          title: {
            align: 'high',
            offset: 0,
            text: '(时间)',
            rotation: 0,
            y: -5,
            x: 40,
            style: { color: '#7FC8FF' }
          },
          labels: {
            style: { color: '#7FC8FF' }
          }
        },
        yAxis: {
          tickAmount: 4,
          lineWidth: 1,
          title: {
            align: 'high',
            offset: 0,
            text: '(单位：册)',
            rotation: 0,
            y: -10,
            x: 30,
            style: { color: '#7FC8FF' }
          },
          labels: {
            style: { color: '#7FC8FF' }
          },
          gridLineColor: '#00D7F6',
          lineColor: '#00D7F6'
        },
        plotOptions: {
          column: {
            borderColor: '', // 边框
            shadow: true, // 阴影
            dataLabels: {
              // 柱状图数据标签
              enabled: true, // 是否显示数据标签
              color: '#e3e3e3', // 数据标签字体颜色
              formatter: function () {
                // 格式化输出显示
                return this.y
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat:
            '<span>{point.name}</span>: <b>{point.y}</b> 册<br/>'
        },
        title: {
          text: ''
        },
        series: [
          {
            data: this.datalist
          }
        ]
      })
    }
  },
  watch: {
    reviewall: {
      handler (newValue) {
        console.log(newValue)
        this.title = newValue.title
        newValue.data.forEach((item, index) => {
          if (index == 0) {
            this.datalist.push({
              name: `${item.name}年`,
              y: item.y,
              color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, '#285aa7'],
                  [1, '#74b8f7']
                ]
              }
            })
          } else if (index == 1) {
            this.datalist.push({
              name: `${item.name}年`,
              y: item.y,
              color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, '#27b074'],
                  [1, '#a6f7c1']
                ]
              }
            })
          } else if (index == 2) {
            this.datalist.push({
              name: `${item.name}年`,
              y: item.y,
              color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, '#eb9861'],
                  [1, '#fef4a4']
                ]
              }
            })
          }
        })
        this.beginChar()
      }
    }
  },
  mounted () {
  }
}
</script>
<style scoped>
.first-home {
  /* background: url("../../../assets/left.jpg"); */
  background: #142c89;
  background-size: 100% 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 20%;
  /* border: 2px solid rgb(60, 129, 195); */
}

#firstChart {
  width: 100%;
  flex: 1;
}
</style>
