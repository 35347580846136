<template>
  <div class="first-home">
    <Header :titleName="title"/>
    <div id="firstRightChart"></div>
  </div>
</template>
<script>
import Highcharts from 'highcharts'
import Header from '../../../components/header-title.vue'
export default {
  components: {
    Header
  },
  props: ['top'],
  data () {
    return {
      datalist: [],
      title: ''
    }
  },
  methods: {
    beginChar () {
      Highcharts.chart('firstRightChart', {
        chart: {
          type: 'column',
          backgroundColor: '#142c89',
          margin: [30, 50, 50, 50],
          padding: [0, 0, 0, 0]
        },
        xAxis: {
          type: 'category',
          gridLineColor: '#00D7F6',
          lineColor: '#00D7F6',
          title: {
            align: 'high',
            offset: 0,
            text: '',
            rotation: 0,
            y: -5,
            x: 40,
            style: { color: '#00D7F6' }
          },
          labels: {
            rotation: -30,
            // y:5,
            style: { color: '#00D7F6', fontSize: '6px' }
          }
        },
        yAxis: {
          lineWidth: 1,
          title: {
            align: 'high',
            offset: 0,
            text: '(单位：册)',
            rotation: 0,
            y: -10,
            x: 30,
            style: { color: '#00D7F6' }
          },
          labels: {
            style: { color: '#00D7F6' }
          },
          gridLineColor: '#00D7F6',
          lineColor: '#00D7F6',
          tickAmount: 4
        },
        plotOptions: {
          column: {
            borderColor: '', // 边框
            shadow: true, // 阴影
            dataLabels: { // 柱状图数据标签
              enabled: true, // 是否显示数据标签
              color: '#e3e3e3', // 数据标签字体颜色
              formatter: function () { // 格式化输出显示
                return (this.y)
              }
            }
          }
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat:
            '<span>{point.name}</span>: <b>{point.y}</b> 册<br/>'
        },
        title: {
          text: ''
        },
        series: [
          {
            data: this.datalist
          }
        ]
      })
    }
  },
  watch: {
    top: {
      handler (newValue) {
        this.title = newValue.title
        newValue.data.forEach(item => {
          this.datalist.push({
            name: item.name,
            y: item.y,
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, '#eb6363'],
                [1, '#f0ee88']
              ]
            }
          })
        })
        this.beginChar()
      }
    }
  },
  mounted () {
  }
}
</script>
<style scoped>
  .first-home {
  background: #142c89;
  background-size: 100% 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-top: 20%;
}
#firstRightChart {
  width: 100%;
  flex: 1;
}
</style>
