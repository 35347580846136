<template>
  <div class="home">
    <leftPage
      class="first"
      :reviewall="chartData.reviewall"
      :reviewquantity="chartData.reviewquantity"
      :unitamount="chartData.unitamount"
    ></leftPage>
    <midPage class="second" :books="chartData.books" :year="year" :total="total"></midPage>
    <rightPage
      class="third"
      :classify="chartData.classify"
      :top="chartData.top"
      :problem="chartData.problem">
    </rightPage>
  </div>
</template>
<script>
import leftPage from './leftPart/leftPage.vue'
import midPage from './midPart/midPage.vue'
import rightPage from './rightPart/rightPage.vue'
import Axios from 'axios'
export default {
  components: {
    leftPage,
    midPage,
    rightPage
  },
  data () {
    return {
      chartData: {
        books: [],
        classify: [],
        problem: [],
        reviewall: [],
        reviewquantity: [],
        top: [],
        unitamount: []
      },
      year: new Date().getFullYear(),
      total: 0
    }
  },
  methods: {
    async getChartData (year) {
      const result = await Axios({
        url: '/admin/statistic/screen',
        method: 'post',
        data: {
          year: year
        }
      })
      if (result.data.code == 200) {
        this.chartData.books = result.data.data.books
        this.chartData.classify = result.data.data.classify
        this.chartData.problem = result.data.data.problem
        this.chartData.reviewall = result.data.data.reviewall
        this.chartData.reviewquantity = result.data.data.reviewquantity
        this.chartData.top = result.data.data.top
        this.chartData.unitamount = result.data.data.unitamount
        this.total = result.data.data.total
        result.data.data.year = this.year
        const sessionData = JSON.stringify(result.data.data)
        window.sessionStorage.setItem('firstPageData', sessionData)
      }
    }
  },
  mounted () {
    const sessionData = window.sessionStorage.getItem('firstPageData')
    if (sessionData) {
      const sessionDataJson = JSON.parse(sessionData)
      this.total = sessionDataJson.total
      this.year = sessionDataJson.year
      this.chartData = { ...sessionDataJson }
    } else {
      var query = window.location.search.substring(1)
      if (query) {
        const year = query.split('=')[1]
        this.year = year
        this.getChartData(year)
      } else {
        this.getChartData(this.year)
      }
    };
  }
}
</script>
<style scoped>
.home {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: url("../../assets/55.jpg");
  background-size: 100% 100%;
  height: 100%;
  position: fixed;
  width: 100%;
}
.first {
  flex: 1;
}
.second {
  flex: 1;
}
.third {
  flex: 1;
}
</style>
