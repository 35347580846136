<template>
  <div class="first-home">
    <Header :titleName="title" style="cursor: pointer" :type="'next'" />
    <div id="thirdRightChart"></div>
  </div>
</template>
<script>
import Highcharts from "highcharts";
import Header from "../../../components/header-title.vue";
import Vue from "vue";
export default {
  components: {
    Header,
  },
  props: ["problem"],
  data() {
    return {
      datalist: [],
      title: "",
      year: [],
    };
  },
  methods: {
    goNext() {
      console.log(1111);
      console.log(Vue.prototype);
    },
    beginChar() {
      Highcharts.chart("thirdRightChart", {
        chart: {
          backgroundColor: "#142c89",
          margin: [30, 50, 30, 50],
          padding: [0, 0, 0, 0],
        },
        title: {
          text: "",
        },
        xAxis: {
          lineColor: "rgb(37,36,28)",
          categories: this.year,
          title: {
            align: "high",
            offset: 0,
            text: "(时间)",
            rotation: 0,
            y: -5,
            x: 40,
            style: { color: "#00D7F6" },
          },
          labels: {
            rotation: 0,
            style: { color: "#00D7F6" },
          },
        },
        yAxis: {
          lineWidth: 1,
          lineColor: "rgb(37,36,28)",
          gridLineColor: "rgb(37,36,28)",
          grideLineColor: "rgb(37,36,28)",
          tickAmount: 4,
          title: {
            text: "",
          },
          labels: {
            rotation: 0,
            style: { color: "#00D7F6" },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          line: {
            lineWidth: 1,
            lineColor: "#00D7F6",
            events: {
              click: (e) => {
                  const _prop = Vue.prototype;
                  _prop.changePage(e.point.name);
              },
            },
          },
        },
        series: [
          {
            name: "种类",
            data: this.datalist,
          },
        ],
      });
    },
  },
  watch: {
    problem: {
      handler(newValue) {
        this.datalist = [];
        this.year = [];
        this.title = newValue.title;
        newValue.data.forEach((item, index) => {
          this.year.push(`${item.name}年`);
          if (index == 0) {
            this.datalist.push({
              name: `${item.name}`,
              y: item.y,
              color: "#00D7F6",
            });
          }
          if (index == 1) {
            this.datalist.push({
              name: `${item.name}`,
              y: item.y,
              color: "#26FFA8",
            });
          }
          if (index == 2) {
            this.datalist.push({
              name: `${item.name}`,
              y: item.y,
              color: "#FFFB94",
            });
          }
        });
        this.beginChar();
      },
    },
  },
  mounted() {},
};
</script>
<style scoped>
.first-home {
  /* background: url("../../../assets/left.jpg"); */
  background: #142c89;
  background-size: 100% 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  /* border: 2px solid rgb(60, 129, 195); */
}
#thirdRightChart {
  width: 100%;
  flex: 1;
}
</style>
