import Vue from 'vue'
import App from './App.vue'
import router from './router'
import highcharts from 'highcharts'
import cylinder from 'highcharts/modules/cylinder'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)
cylinder(highcharts)
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
